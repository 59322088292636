<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-12 mb-5">
        <label>{{ $t('status') }}</label>
        <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
          <option v-for="row in statusList" :value="row.id" :key="row.id" :disabled="checkIgnoreSelectStatus(row.id)">{{ row.title }}</option>
        </select>
        <span v-if="old_status > 1 && data.status == 1" class="fv-plugins-message-container invalid-feedback">
                    {{ $t('you_cannot_change_the_status_Please_check_with_your_system_administrator') }}
                </span>
        <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.status[0] }}
                </span>
      </div>
    </div>
    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save" :disabled="old_status > 1 && data.status == 1">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-change-status-modal",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    statusList: {type: Array},
    ignoreSelectedStatus: {type: Array, default: () => [], required: false},
    url: {type: String},
    currentId: null,
    currentStatus: null,
    handlingStatus: {type: Function},
    showConfirmMessage: {type: Boolean, default: false},
  },
  data() {
    return {
      data: {
        status: null,
        id: null,
      },
      validation: null,
      old_status: null,
    };
  },
  watch: {
    'data.status': function (val) {
      if (val) {
        this.$emit('handling-status', this.data.status);
      }
    },
    currentStatus: function (val) {
      if (val) {
        this.data.status = this.currentStatus;
        this.old_status = this.currentStatus;
        this.data.id = this.currentId;
      }
    },
    currentId: function (val) {
      if (val) {
        this.data.status = this.currentStatus;
        this.old_status = this.currentStatus;
        this.data.id = this.currentId;
      }
    },
  },
  methods: {
    save() {
      this.changeStatus();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    changeStatus(params = []) {
      ApiService.patch(this.url + '/' + this.data.id, {
        status: this.data.status,
        ...params
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch(error => {
        Vue.prototype.$postStatus = true;
        if (this.showConfirmMessage) {
          if (error.response.data && error.response.data.errors && error.response.data.errors.balance > 0) {
            this.$confirmAlert(
                error.response.data.message ? error.response.data.message : '',
                this.changeStatus,
                {action: 'confirm'}
            );
          } else {
            this.$errorAlert(error);
          }
        } else {
          this.$errorAlert(error);
        }


      });
    },
    resetAfterSave() {
      this.data.status = null;
      this.old_status = null;
      this.data.id = null;
    },
    checkIgnoreSelectStatus(status) {
      if (this.ignoreSelectedStatus.length > 0){
        return this.ignoreSelectedStatus.includes(status)
      }
      return false;
    },
  },

  mounted() {
    this.data.status = this.currentStatus;
    this.old_status = this.currentStatus ? parseInt(this.currentStatus) : null;
    this.data.id = this.currentId;

  },
};
</script>